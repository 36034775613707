import React from "react"
import { css } from "emotion"

function MediaTile({
  children,
  backgroundImage,
  backgroundColor,
  color,
  backgroundPosition,
  backgroundSize,
  minHeight,
  backgroundOpacity
}) {
  if (!backgroundOpacity){ backgroundOpacity = 0; }

  return (    
    <div>
      <div
        style={{
          backgroundColor: backgroundColor,
          background:
            backgroundImage &&
            `linear-gradient(0deg,rgba(255,255,255,${backgroundOpacity}),rgba(255,255,255,${backgroundOpacity})),url('${backgroundImage}')`,
          backgroundPosition: backgroundPosition,
          backgroundSize: backgroundSize ? backgroundSize : "cover",
          textShadow: backgroundImage && "2px 2px 15px #3f3f3f",
          backgroundRepeat: "no-repeat",
          color: color,
          minHeight: minHeight ? minHeight : "250px",          
        }}
        className={styles}>
        {children}
      </div>
    </div>
  )
}

export default MediaTile

const styles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;  
  padding: 15px;
`
