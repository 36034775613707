import React from "react"
import { css } from "emotion"

function Button({ children, href, target }) {
  return (
    <a href={href} target={target} className={styles}>
      {children}
    </a>
  )
}

export default Button

const styles = css`
  border-radius: 40px;
  padding-left: 40px;
  padding-right: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #37679C;
  color: white;
  border: none;
  text-decoration: none;
  transition-property: background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: #0A273B;
    cursor: pointer;
  }
`
